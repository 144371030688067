<template>
  <page-template :title="$t('pagePlans.title')">
    <div class="tariffs-page">
      <page-subtitle class="subtitle subtitle--primary">
        {{ $t('pagePlans.subtitle') }}
      </page-subtitle>
      <page-subtitle class="subtitle subtitle--secondary">
        {{ $t('pagePlans.urPlan') }}: {{ selectedTariffPlanTranslation }}
      </page-subtitle>
      <div v-if="tariffs.length" class="content">
        <div class="plans">
          <free-plan-card :info="freeTariffInfo" class="tariffs-page__block" @sign-up="onSignUp" />
          <premium-plan-card
            :info="premiumTariffInfo"
            class="tariffs-page__block"
            @subscribe="onSubscribe"
            @unsubscribe="onUnsubscribe"
            @sign-up="onSignUp"
          />
        </div>
        <!--        <premium-discount
          v-if="!isProduction"
          :info="discountTariffs"
          class="tariffs-page__discount"
          @subscribe="onSubscribe"
        />-->
      </div>
    </div>
    <!--    <payment-dialog
      :show="showPaymentDialog"
      @pay-by-cloudpayments="onPayByCloudpayments"
      @pay-by-stripe="onPayByStripe"
      @close="onClosePaymentDialog"
    />-->
  </page-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { TARIFF_PLAN, STRIPE_PAYMENT_PLAN, TARIFF_PLAN_TRANSLATION } from '@/types';
import { PageTemplate, PageSubtitle } from '@/elements/components/page';
import { FreePlanCard, PremiumPlanCard } from './plans';
import { addPaymentWidget } from './payment-service';
import PaymentDialog from './PaymentDialog';
import { isProduction } from '@/utils/common';

const FREE_TARIFF_NAME = 'Стандарт';
const PREMIUM_TARIFF_NAME = 'Премиум';
const DISCOUNT_TARIFF_NAMES = ['Премиум на 3 месяца', 'Премиум на 6 месяцев', 'Премиум на год'];
/*import PremiumDiscount from './ PremiumDiscount';*/

export default {
  name: 'TariffsPage',
  components: {
    PremiumPlanCard,
    FreePlanCard,
    PageTemplate,
    PageSubtitle,
    PaymentDialog,
  },
  data() {
    return {
      isProduction,
      showPaymentDialog: false,
      amount: null,
    };
  },
  computed: {
    ...mapGetters('UserModule', ['selectedTariffPlan', 'selectedTariffPlanTranslation', 'isLoggedIn', 'tariffs']),
    freeTariffInfo() {
      return this.tariffs.find(({ name }) => name === FREE_TARIFF_NAME);
    },
    premiumTariffInfo() {
      return this.tariffs.find(({ name }) => name === PREMIUM_TARIFF_NAME);
    },
    discountTariffs() {
      return this.tariffs.filter(({ name }) => DISCOUNT_TARIFF_NAMES.includes(name));
    },
  },
  methods: {
    ...mapActions('UserModule', [
      'cancelSubscription',
      'setSubscription',
      'addNotification',
      'fetchUser',
      'getTariffsInfo',
      'setStripeSubscription',
    ]),
    async onSubscribe({ plan, amount, discount = false }) {
      if (this.selectedTariffPlan !== TARIFF_PLAN.FREE && !discount) {
        const isSubmitted = await this.showUnsubscribeDialog();
        if (!isSubmitted) {
          return;
        }

        try {
          await this.cancelSubscription();
        } catch {
          if (this.$i18n.locale === 'en') {
            await this.addNotification({
              text: 'Failed to cancel the subscription. Please try again',
            });
          } else {
            await this.addNotification({
              text: 'Es hat nicht geklappt, sich vom Abo abzumelden. Bitte versuchen Sie es nochmal etwas später.',
            });
          }
          return;
        }
      }

      if (plan !== TARIFF_PLAN.FREE) {
        this.amount = amount;
        // позже верни
        //this.showPaymentDialog = true;
        await this.onPayByCloudpayments();
        this.showPaymentDialog = true;
        /*try {
          await this.setSubscription({ plan, amount });
          if (this.$i18n.locale === 'en') {
            await this.addNotification({
              text: `You have successfully started the subscription ${this.$i18n.t([plan])}.`,
            });
            await this.fetchUser().catch();
            await this.$showConfirmDialog('The plan will be activated within 2 minutes. Please update the page', 'Ok');
          } else {
            await this.addNotification({
              text: `Sie haben sich erfolgreich für ein Abo registriert  ${this.$i18n.t([plan])}.`,
            });
            await this.fetchUser().catch();
            await this.$showConfirmDialog(
              'Ihr Tarif wird innerhalb von zwei Minuten aktiviert. Bitte laden Sie die Seite neu.',
              'Ok',
            );
          }
        } catch {
          if (this.$i18n.locale === 'en') {
            await this.addNotification({ text: 'Failed to start the subscription. Please try again' });
          } else {
            await this.addNotification({
              text: 'Es hat sich erfolgreich, sich vom Abo abzumelden. Bitte versuchen Sie es nochmal etwas später.',
            });
          }
        }*/
      } else {
        await this.fetchUser().catch();
        if (this.$i18n.locale === 'en') {
          await this.addNotification({ text: 'You have successfully cancelled the paid subscription' });
        } else {
          await this.addNotification({ text: 'Sie haben sich vom kostenpflichtigen Abo erfolgreich abgemeldet' });
        }
      }
    },
    async onUnsubscribe() {
      const isSubmitted = await this.showUnsubscribeDialog();
      if (!isSubmitted) {
        return;
      }

      const oldTariffPlan = this.selectedTariffPlan;
      try {
        await this.cancelSubscription();
        await this.fetchUser().catch();
        if (this.$i18n.locale === 'en') {
          await this.addNotification({
            text: `You have successfully cancelled the subscription ${this.$i18n.t([oldTariffPlan])}`,
          });
        } else {
          await this.addNotification({
            text: `Sie haben sich vom Abo erfolgreich abgemeldet ${this.$i18n.t([oldTariffPlan])}`,
          });
        }
      } catch (error) {
        if (this.$i18n.locale === 'en') {
          await this.addNotification({ text: 'Failed to cancel the subscription. Please try again' });
        } else {
          await this.addNotification({
            text: 'Es hat nicht geklappt, sich vom Abo abzumelden. Bitte versuchen Sie es nochmal etwas später.',
          });
        }
      }
    },
    async showUnsubscribeDialog() {
      if (this.$i18n.locale === 'en') {
        return this.$showConfirmDialog('Are you sure you want to unsubscribe?', 'Cancel', 'Keep');
      } else {
        return this.$showConfirmDialog('Möchten Sie wirklich Ihr Abo kündigen?', 'Abbrechen', 'Lassen');
      }
    },
    onSignUp() {
      this.$router.push({ name: 'signUp' });
    },
    async onPayByCloudpayments() {
      try {
        this.showPaymentDialog = false;
        await this.setSubscription({ plan: TARIFF_PLAN.PREMIUM, amount: this.amount });
        await this.addNotification({
          text: `Вы успешно оформили подписку ${TARIFF_PLAN_TRANSLATION[TARIFF_PLAN.PREMIUM]}.`,
        });
        await this.fetchUser().catch();
        await this.$showConfirmDialog('Тариф вступит в силу в течение 2-х минут. Обновите страницу.', 'Ок');
      } catch {
        await this.addNotification({ text: 'Не удалось оформить подписку. Попробуйте еще раз позже.' });
      }
    },
    async onPayByStripe() {
      this.showPaymentDialog = false;
      await this.setStripeSubscription(STRIPE_PAYMENT_PLAN.PREMIUM);
    },
    onClosePaymentDialog() {
      this.showPaymentDialog = false;
    },
    showStripeResultMessage() {
      const { query } = this.$route;
      if (!query.subscription_status) return;

      if (query.subscription_status === 'success') {
        this.addNotification({
          text: `Вы успешно оформили подписку ${TARIFF_PLAN_TRANSLATION[TARIFF_PLAN.PREMIUM]}.`,
        });
      } else if (query.subscription_status === 'cancel') {
        this.addNotification({ text: 'Не удалось оформить подписку. Попробуйте еще раз позже.' });
      }
    },
  },
  mounted() {
    this.getTariffsInfo();
    addPaymentWidget();
    this.showStripeResultMessage();
  },
};
</script>

<style scoped lang="sass">
.tariffs-page
  width: 100%
  display: flex
  flex-direction: column
  color: grey-color('500')

  &__block
    width: 100%
    max-width: base-unit(360)

  &__discount
    width: base-unit(270)
    margin-top: base-unit(20)

    +medium-devices
      margin-left: base-unit(20)

.subtitle
  align-self: center
  margin-bottom: base-unit(20)

  +medium-devices
    align-self: unset

  &--primary
    font-weight: 500

  &--secondary
    +medium-devices
      display: none

.content
  display: flex
  flex-direction: column
  width: 100%
  align-items: center

  ::v-deep .v-label
    flex-wrap: wrap
    white-space: pre-wrap

  +medium-devices
    flex-direction: row
    align-items: flex-start
    flex-wrap: wrap

.plans
  display: flex
  flex-direction: column
  align-items: center
  grid-gap: base-unit(20)
  width: 100%

  +medium-devices
    flex-direction: row
    align-items: unset
    width: auto
</style>
