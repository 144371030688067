<template>
  <div class="root">
    <div class="header" @click="onExpansionChange">
      <span class="name" :class="{ 'user-premium': isPremiumPlan }">{{ name }}</span>
      <slot name="header"></slot>
      <collapse-button v-if="isMobileScreen" class="arrow" :expanded="isExpanded" />
    </div>
    <collapse-transition>
      <div class="content-wrapper" v-show="!isMobileScreen || isExpanded">
        <div class="content">
          <div class="item" v-for="option in options" :key="option.label">
            <img width="14" class="item-icon" alt="" :src="getIconByStatus(option.isPresent)" />
            <span class="item-label">{{ $t('pagePlans.options.' + option.label) }}</span>
          </div>
          <slot name="content"></slot>
        </div>
        <div class="actions">
          <template v-if="isLoggedIn">
            <astro-button
              v-if="isPremiumPlan && !selected"
              class="actions__btn actions__btn--transparent"
              :title="$t('unavailable')"
              disabled="true"
              @click="onSubscribe"
            />
            <div v-else-if="isPremiumPlan && selected" class="premium-selected">
              <div class="premium-selected__content">
                <span class="premium-selected__title">{{ $t('pagePlans.activeSubscribe') }}</span>
                <span class="premium-selected__date">{{ $t('pagePlans.renew', { date: nextPaymentDate }) }}</span>
              </div>
              <action-view class="premium-selected__btn actions__btn" @click="onUnsubscribe">
                {{ $t('pagePlans.cancelPlan') }}
              </action-view>
            </div>
            <span v-else-if="!isPremiumPlan && selected" class="actions__free">{{ $t('pagePlans.urPlan') }}</span>
          </template>
          <astro-button
            v-else
            class="actions__btn"
            :class="{ 'actions__btn--transparent': !isPremiumPlan }"
            :title="$t('login.signUp')"
            @click="onSignUp"
          />
          <slot name="actions"></slot>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ActionView from '@/elements/atoms/ActionView';
import AstroButton from '@/elements/components/AstroButton';
import CollapseButton from '@/elements/components/CollapseButton';
import { CollapseTransition } from '@/elements/components/transitions';

import TickIcon from '@/assets/icons/tariff_tick.svg';
import CrossIcon from '@/assets/icons/tariff_cross.svg';
import { TARIFF_PLAN } from '@/types';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'TariffCard',
  components: { ActionView, AstroButton, CollapseButton, CollapseTransition },
  props: {
    plan: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters('UserModule', ['selectedTariffPlan', 'isLoggedIn', 'nextPaymentDate']),
    selected() {
      return this.selectedTariffPlan === this.plan;
    },
    name() {
      return i18n.t([this.plan]);
    },
    isPremiumPlan() {
      return this.plan === TARIFF_PLAN.PREMIUM;
    },
  },
  methods: {
    onExpansionChange() {
      this.isExpanded = !this.isExpanded;
    },
    onSubscribe() {
      this.$emit('subscribe');
    },
    onUnsubscribe() {
      this.$emit('unsubscribe');
    },
    onSignUp() {
      this.$emit('sign-up');
    },
    getIconByStatus(isPresent) {
      if (isPresent) {
        return TickIcon;
      }

      return CrossIcon;
    },
  },
};
</script>

<style scoped lang="sass">
.root
  display: flex
  flex-direction: column
  height: fit-content
  width: 100%
  border-radius: base-unit(4)
  padding: base-unit(20) base-unit(10)
  background-color: white
  +medium-devices
    height: base-unit(550)
    padding: base-unit(20)
    border: none

.header
  display: flex
  align-items: center
  width: 100%

.arrow
  margin-left: auto

.content-wrapper
  +medium-devices
    max-height: unset

.name
  color: grey-color('600')
  font-weight: 500
  font-size: base-unit(16)
  margin-right: base-unit(8)
  &.user-premium
    color: blue-color('primary')

.content
  display: flex
  flex-direction: column
  margin: base-unit(16) 0
  +medium-devices
    margin: base-unit(20) 0

.list
  list-style-position: outside

.item
  display: flex
  color: grey-color('500')
  font-size: base-unit(16)
  font-weight: 400
  padding: base-unit(5) 0
  align-items: center
  +medium-devices
    align-items: unset
    justify-content: unset
    padding: 0
    font-size: base-unit(16)
    border: none

  &:last-child
    +medium-devices
      margin-bottom: 0

  &-icon
    height: base-unit(16)
    order: 2
    padding-bottom: base-unit(8)
    margin-right: base-unit(12)
    +medium-devices
      order: unset
      height: base-unit(30)

  &-label
    order: 2
    +medium-devices
      order: unset
      min-height: base-unit(30)

.actions
  opacity: 1
  display: flex
  flex-direction: column
  align-items: center
  &__btn
    width: 100%
    height: base-unit(36)
    border-radius: base-unit(4)
    +medium-devices
      width: base-unit(320)
    &--transparent
      color: blue-color('primary')
      border: base-unit(1) solid blue-color('primary')
      background-color: white
      &:hover
        color: white
    &--cancel
      width: base-unit(114)
      color: grey-color('500')
      border: base-unit(1) solid grey-color('100')
  &__free
    margin-top: base-unit(7)
    color: grey-color('75')

.premium-selected
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  &__content
    display: flex
    flex-direction: column
  &__title
    color: blue-color('primary')
  &__date
    color: grey-color('75')
    white-space: nowrap
  &__btn
    width: auto
    padding: 0 base-unit(20)
    margin-left: base-unit(10)
    color: grey-color('500')
    border: base-unit(1) solid grey-color('100')
</style>
