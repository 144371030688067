/*const tariffOptions = [
  'Таблица координат',
  'Таблица аспектов',
  'Анализ карты',
  'История карт',
  'Выбор системы домов',
  'Настройка орбисов',
  'Выбор широты для построения северных карт',
  'Полная интерпретация планет в знаках и домах',
  'Цепочки планет',
  'Выбор города на карте',
  'Выбор метода расчета орбисов',
  'Интерпретация аспектов',
];*/

const tariffOptions = [
  'tableCoords',
  'tableAspects',
  'analyzeMap',
  'historyMap',
  'choiceHouseSystem',
  'orbitsSettings',
  'chainPlanets',
  'choiceTown',
  'choiceCalcOrbits',
  'integrationAspects',
];

export const buildTariffOptions = (switchedOffOptions) => {
  return tariffOptions.map((option) => {
    const isPresent = !switchedOffOptions.find((opt) => opt === option);
    return {
      label: option,
      isPresent,
    };
  });
};
