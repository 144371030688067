<template>
  <v-dialog v-model="showDialog" width="fit-content" max-width="500">
    <v-card class="payment-dialog">
      <cross-button class="payment-dialog__close-btn" color="grey" @click="onClose" />
      <span class="payment-dialog__title">Выберите платёжную систему</span>
      <div class="payment-dialog__systems">
        <div class="payment-dialog__system-item" @click="onPayByCloudpayments">
          <span class="payment-dialog__title">Для карт, выпущенных в России</span>
          <img :src="require('@/assets/tariffs/cloudpayments-logo.svg')" alt="cloudpayments" />
          <span class="payment-dialog__title">Cloudpayments</span>
        </div>
        <div class="payment-dialog__system-item" @click="onPayByStripe">
          <span class="payment-dialog__title">Для карт, выпущенных не в России</span>
          <img :src="require('@/assets/tariffs/stripe-logo.svg')" alt="stripe" />
          <span class="payment-dialog__title">Stripe</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CrossButton from '@/elements/components/CrossButton';

export default {
  name: 'PaymentDialog',
  components: { CrossButton },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.onClose();
      },
    },
  },
  methods: {
    onPayByCloudpayments() {
      this.$emit('pay-by-cloudpayments');
    },
    onPayByStripe() {
      this.$emit('pay-by-stripe');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-dialog
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  padding: base-unit(30)
  border-radius: base-unit(4)
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05)

  &__close-btn
    position: absolute
    right: base-unit(10)
    top: base-unit(10)

    +medium-devices
      right: base-unit(15)
      top: base-unit(15)

  &__title
    text-align: center
    color: grey-color('500')
    font-weight: 500
    font-size: $base-font-size

  &__systems
    display: flex
    align-items: center
    flex-direction: column
    gap: base-unit(20)
    margin-top: base-unit(20)

    +medium-devices
      flex-direction: row

  &__system-item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    width: base-unit(210)
    height: base-unit(200)
    padding: base-unit(15)
    border: 1px solid grey-color('75')
    border-radius: base-unit(6)
    cursor: pointer
</style>
