<template>
  <action-view @click="$emit('input')">
    <img
      :src="require('@/assets/icons/arrow-down-blue.svg')"
      class="arrow-img"
      :class="{ 'arrow-img-collapsed': !expanded }"
      height="11"
      width="13"
      alt=""
    />
  </action-view>
</template>

<script>
import ActionView from '@/elements/atoms/ActionView';

export default {
  name: 'CollapseButton',
  components: {
    ActionView,
  },
  model: {
    prop: 'expanded',
    event: 'input',
  },
  props: {
    expanded: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="sass">

.arrow-img
    transition: all 0.3s linear
    &-collapsed
      transform: rotate(180deg)
</style>
