<template>
  <tariff-card
    class="premium-plan"
    :plan="plan"
    :options="options"
    @subscribe="onSubscribe"
    @unsubscribe="$emit('unsubscribe')"
    @sign-up="onSignUp"
  >
    <template v-slot:header>
      <span class="label-cost">{{ amount }} &#36;</span>
      <span class="label-period"> / {{ $t('pagePlans.month') }}</span>
    </template>
  </tariff-card>
</template>

<script>
import { TARIFF_PLAN } from '@/types';
import TariffCard from './TariffCard';
import { buildTariffOptions } from './tariff-options';

const options = buildTariffOptions([]);

export default {
  name: 'PremiumPlanCard',
  components: { TariffCard },
  data() {
    return {
      options,
      plan: TARIFF_PLAN.PREMIUM,
      amount: 19.96,
    };
  },
  methods: {
    onSubscribe() {
      this.$emit('subscribe', {
        plan: this.plan,
        amount: this.amount,
      });
    },
    onSignUp() {
      this.$emit('sign-up');
    },
  },
};
</script>

<style scoped lang="sass">
.premium-plan
  border: base-unit(1) solid blue-color('primary')
  background-color: white

.label
  &-cost
    color: #8BBFD0
    font-weight: normal
    font-size: base-unit(16)

  &-period
    color: #8BBFD0
    font-size: base-unit(16)
    font-weight: normal
</style>
