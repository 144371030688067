<template>
  <tariff-card class="free-plan" :plan="plan" :options="options" :show-cancel-button="false" @sign-up="onSignUp">
    <template v-slot:header>
      <span class="label-free">{{ $t('pagePlans.free') }}</span>
    </template>
  </tariff-card>
</template>

<script>
import { TARIFF_PLAN } from '@/types';
import TariffCard from './TariffCard';
import { buildTariffOptions } from './tariff-options';

const switchedOffOptions = ['chainPlanets', 'choiceTown', 'choiceCalcOrbits', 'integrationAspects'];
/*const switchedOffOptions = [
  'Выбор широты для построения северных карт',
  'Полная интерпретация планет в знаках и домах',
  'Цепочки планет',
  'Выбор города на карте',
  'Выбор метода расчета орбисов',
  'Интерпретация аспектов',
];*/
const options = buildTariffOptions(switchedOffOptions);

export default {
  name: 'FreePlanCard',
  components: { TariffCard },
  data() {
    return {
      options,
      plan: TARIFF_PLAN.FREE,
    };
  },
  methods: {
    onSignUp() {
      this.$emit('sign-up');
    },
  },
};
</script>

<style scoped lang="sass">
.free-plan
  border: 1px solid grey-color('10')

.label-free
  color: #8BBFD0
  font-weight: normal
  font-size: base-unit(16)
  +medium-devices
    font-size: base-unit(16)
</style>
